import React, { CSSProperties, FC, useEffect, useState } from "react";
import { KatButton, KatSpinner } from "@amzn/katal-react";
import { log, LogLevel } from "src/logger";
import { useInboundDocument } from "src/components/Contexts/InboundDocumentContext";
import {
  handleValidationUserAction,
  ValidationUserActionHandlerProps,
} from "src/components/UserActions/Handlers/Validation/validation_user_action_handler";
import { ModalWrapper } from "src/components/Modal/ModalWrapper";
import { DataTableCellChildComponentProps } from "src/components/DataTable/DataTableDefinition";
import {
  ValidationGroup,
  ValidationGroupStatus,
} from "src/api/interfaces/document_validation";
import { useDocumentCompliance } from "src/components/Contexts/DocumentComplianceContext";
import {
  UserAction,
  ValidationUserActionType,
} from "src/components/UserActions/types";
import { ValidationUserActionsDropdown } from "src/components/UserActions/ValidationUserActionsDropdown";
import client from "src/api/client";
import { SaveOverriddenMetadataRequest } from "src/api/interfaces/inbound_document";
import { useValidationResults } from "src/components/Contexts/ValidationResultsContext";
import withPermissionsOrDefault from "src/components/AICLayout/AICHeader/Permissions/WithPermissions";
import { Operation } from "src/api/interfaces/permissions";
import { useTranslation } from "react-i18next";
import { getTranslatedValue } from "src/utils";
import { alertHandler } from "src/components/Alert/handlers";
import { useAlertContext } from "src/components/Alert/AlertContext";
import styles from "src/styles.module.scss";
import classnames from "classnames";
import ValidationModalRenderComponent from "src/components/Pages/DocumentInbound/Tabs/Validations/ValidationsModals/ValidationModalRenderComponent";
import ValidationSeverityDescriptionExpander from "src/components/Pages/DocumentInbound/ValidationSeverityDescription/ValidationSeverityDescriptionExpander";

const dropdownStyles: CSSProperties = {
  float: "right",
  right: 30,
};

export type ValidationUserActionsProps =
  DataTableCellChildComponentProps<ValidationGroup>;

const FIX_LABEL = "aic_doc_page_validation_user_actions_fix_label";
const REVIEW_LABEL = "aic_doc_page_validation_user_actions_review_label";
const FIX_VARIANT = "primary";
const REVIEW_VARIANT = "secondary";

const ValidationUserActions: FC<ValidationUserActionsProps> = ({
  content,
}: ValidationUserActionsProps) => {
  const { t } = useTranslation();
  const [ValidationHandler, setValidationHandler] =
    useState<FC<ValidationUserActionHandlerProps>>();
  const [loading, setLoading] = useState<boolean>(false);
  const [currentUserAction, setCurrentUserAction] = useState<
    UserAction | undefined
  >();
  const inboundDocument = useInboundDocument();
  const documentCompliance = useDocumentCompliance();
  const { validationResultsByGroup } = useValidationResults();

  const [handlerModalVisible, setHandlerModalVisible] =
    useState<boolean>(false);
  const { setAlerts, alerts } = useAlertContext();
  const userActions = documentCompliance?.userActionsByValidationGroup?.[
    content.validationGroupId
  ]?.filter(({ type }) => type !== ValidationUserActionType.VIEW);
  const viewUserAction = documentCompliance?.userActionsByValidationGroup?.[
    content.validationGroupId
  ]?.filter(({ type }) => type === ValidationUserActionType.VIEW)?.[0];

  const haveUserActionsAvailable = () => !!userActions;

  const shouldDisplayValidationUserActionsButton = () => {
    return (
      content.validationGroupStatus !== ValidationGroupStatus.SUCCESS &&
      content.validationGroupStatus !== ValidationGroupStatus.PENDING &&
      content.validationGroupStatus !== ValidationGroupStatus.SKIPPED
    );
  };

  const isValidationResultsValid = () => {
    return (
      validationResultsByGroup[content.validationGroupId]?.validationResults
        .length > 0
    );
  };

  const onActionSelected = (userAction: UserAction): void => {
    setLoading(true);

    handleValidationUserAction(inboundDocument, userAction)
      .then((HandlerComponent) => {
        log({
          level: LogLevel.INFO,
          message: "Validation user action handled successfully",
          operationNamespace:
            "ValidationUserActions.handleValidationUserAction",
          attributes: { userAction },
        });
        setLoading(false);
        setValidationHandler(() => HandlerComponent);
        setCurrentUserAction(userAction);
      })
      .catch((error) => {
        log({
          level: LogLevel.ERROR,
          message: "There was an error while handling validation user actions",
          operationNamespace:
            "ValidationUserActions.handleValidationUserAction",
          exception: error?.message,
          attributes: { userAction },
        });
        setLoading(false);
        setHandlerModalVisible(false);
        setCurrentUserAction(undefined);
      });
  };

  const onSubmit = (overriddenMetadata: { [key: string]: string }) => {
    const request: SaveOverriddenMetadataRequest = {
      countryCode: inboundDocument.countryCode,
      documentId: inboundDocument.documentId,
      documentType: inboundDocument.documentType,
      attributes: overriddenMetadata,
      shouldRestartComplianceProcess: true,
    };
    setLoading(true);
    setCurrentUserAction(undefined);
    setValidationHandler(undefined);

    client
      .saveOverriddenMetadata(request)
      .then((response) => {
        log({
          level: LogLevel.INFO,
          message: "Overridden Metadata save started successfully",
          operationNamespace: "ValidationUserActions.saveOverriddenMetadata",
          attributes: { response },
        });
        setLoading(false);
        alertHandler.createNewAlertEntry({
          alerts,
          setAlerts,
          header: t("aic_doc_page_alert_validation_user_action_header_success"),
          fade: true,
          isSuccess: true,
          text: t("aic_doc_page_alert_validation_user_action_text_success"),
        });
      })
      .catch((error) => {
        log({
          level: LogLevel.ERROR,
          message: "There was an error while saving overridden metadata",
          operationNamespace: "ValidationUserActions.saveOverriddenMetadata",
          exception: error?.message,
          attributes: { request },
        });
        setLoading(false);
        alertHandler.createNewAlertEntry({
          alerts,
          setAlerts,
          header: t("aic_doc_page_alert_validation_user_action_header_error"),
          fade: true,
          isSuccess: false,
          text: t("aic_doc_page_alert_validation_user_action_text_error"),
        });
      });
  };

  const onCancel = () => {
    setCurrentUserAction(undefined);
    setValidationHandler(undefined);
  };

  useEffect(() => {
    setCurrentUserAction(undefined);
    setValidationHandler(undefined);
  }, [handlerModalVisible, setCurrentUserAction, setValidationHandler]);

  const wrapperTitle = currentUserAction
    ? currentUserAction?.name
    : content.validationGroupName;

  const validationUserActionsButton =
    shouldDisplayValidationUserActionsButton() ? (
      <>
        {isValidationResultsValid() ? (
          <>
            <ModalWrapper
              title={getTranslatedValue(wrapperTitle as string, t)}
              visible={handlerModalVisible}
              setVisible={setHandlerModalVisible}
              onClose={() => setHandlerModalVisible(false)}
              classNames={[styles.kat_modal]}
            >
              {ValidationHandler ? (
                <ValidationHandler
                  onCancel={onCancel}
                  onSubmit={onSubmit}
                  validationGroup={content}
                  userAction={currentUserAction}
                />
              ) : (
                <>
                  <div
                    className={classnames(
                      styles.display_flex,
                      styles.flex_direction_row,
                      styles.justify_space_between
                    )}
                  >
                    <ValidationSeverityDescriptionExpander />
                    <div style={dropdownStyles}>
                      <ValidationUserActionsDropdown
                        userActions={userActions}
                        onActionSelected={onActionSelected}
                      />
                    </div>
                  </div>
                  <ValidationModalRenderComponent
                    validationGroupId={content.validationGroupId}
                    userAction={viewUserAction}
                  />
                </>
              )}
            </ModalWrapper>
            <KatButton
              id={`validation-${content.validationGroupId}-fix`}
              label={
                haveUserActionsAvailable() ? t(FIX_LABEL) : t(REVIEW_LABEL)
              }
              variant={
                haveUserActionsAvailable() ? FIX_VARIANT : REVIEW_VARIANT
              }
              onClick={() => setHandlerModalVisible(true)}
            />
          </>
        ) : (
          <span />
        )}
      </>
    ) : (
      <span />
    );

  return loading ? <KatSpinner /> : validationUserActionsButton;
};

export default withPermissionsOrDefault(
  ValidationUserActions,
  Operation.ViewInboundDocumentDetails
);
