import {
  AuthoritativeDataPurpose,
  Field,
  MatchingRule,
  MismatchTableFields,
  ValidationResult,
} from "src/api/interfaces/validation_result";
import { MismatchTableRowSorter } from "src/components/Pages/DocumentInbound/Tabs/Validations/ValidationsModals/Mismatch/types";
import { isNullOrUndefined } from "util";
import { isVendorCentral } from "src/api/config";
import { getRuleDetailsApplier } from "./mismatch-table-default-rule-details";

export const EMPTY_FIELD = "-";
const SEPARATOR = ", ";
const ADDRESS_SEPARATOR = " - ";
const ADDRESS_FACET = "AddressFacet";
const CONSUMED_REFERENCED_ITEM_QUANTITY = "consumedReferencedItemQuantity";
const CONSUMED_QUANTITY = "consumedQuantity";

const SHOULD_SWAP = 1;
const SHOULD_NOT_SWAP = -1;

const formatAddressValueField = (value: any): string => {
  const addressLine = [
    value.addressLine1,
    value.addressLine2,
    value.addressLine3,
  ]
    .filter(Boolean)
    .join(SEPARATOR);

  const country = value.countryName || value.countryCode;

  return [addressLine, value.city, value.state, value.zipCode, country]
    .filter(Boolean)
    .join(ADDRESS_SEPARATOR);
};

const convertField = (itemIdentifier: any): string | undefined => {
  if (itemIdentifier && typeof itemIdentifier === "string") {
    if (itemIdentifier.includes(ADDRESS_FACET)) {
      return formatAddressValueField(JSON.parse(itemIdentifier));
    }
    return itemIdentifier.replace(/"/g, "");
  }

  if (
    !isNullOrUndefined(itemIdentifier) &&
    typeof itemIdentifier === "number"
  ) {
    return itemIdentifier.toString();
  }

  return itemIdentifier;
};

const formatExpectedValueField = (
  expectedValue: Array<any> | string
): string => {
  if (Array.isArray(expectedValue)) {
    return expectedValue.join(SEPARATOR);
  }

  return expectedValue;
};

const extractValueForLineItemMatch = (
  rawValue: string,
  rawResultMetadata: string
): string => {
  const rawObjectValue = JSON.parse(rawValue);
  const resultMetadata = JSON.parse(rawResultMetadata);

  if (resultMetadata.hasOwnProperty(CONSUMED_REFERENCED_ITEM_QUANTITY)) {
    return resultMetadata.consumedReferencedItemQuantity;
  }

  return rawObjectValue.quantity.toString();
};

const extractExpectedValueFromRuleMetadata = (
  rule: string | undefined,
  rawRuleMetadata: string
): string | undefined => {
  const ruleMetadata = JSON.parse(rawRuleMetadata);
  let expectedValue: string | undefined;

  switch (rule) {
    case MatchingRule.AT_LEAST_N_OF_CONTAINS:
      expectedValue = formatExpectedValueField(ruleMetadata.acceptedValues);
      break;
    case MatchingRule.CONTAINS_ONLY:
      expectedValue = formatExpectedValueField(ruleMetadata.acceptedValues);
      break;
    case MatchingRule.DOES_NOT_CONTAIN:
      expectedValue = formatExpectedValueField(ruleMetadata.value);
      break;
    case MatchingRule.EQUAL_TO:
      expectedValue = formatExpectedValueField(ruleMetadata.value);
      break;
    case MatchingRule.HIGHER_THAN:
      expectedValue = formatExpectedValueField(ruleMetadata.threshold);
      break;
    case MatchingRule.IS_IN:
      expectedValue = formatExpectedValueField(ruleMetadata.acceptedValues);
      break;
    case MatchingRule.LOWER_THAN:
      expectedValue = formatExpectedValueField(ruleMetadata.threshold);
      break;
    case MatchingRule.MX_MATCH_ADDRESS_TO:
      expectedValue = formatAddressValueField(ruleMetadata.value);
      break;
    case MatchingRule.REGEX:
      expectedValue = formatExpectedValueField(ruleMetadata.pattern);
      break;

    default:
      expectedValue = undefined;
      break;
  }

  return expectedValue;
};

const isLineItemLikeMatch = (rawRule: string | undefined): boolean => {
  return (
    rawRule === MatchingRule.LINE_ITEM_MATCH ||
    rawRule === MatchingRule.CASE_PACK_MATCH
  );
};

const getExpectedValue = (validationResult: ValidationResult, field: Field) => {
  const rawRule = validationResult.rule;
  let expectedValue: string | undefined;

  if (field.purpose === AuthoritativeDataPurpose.REFERENCE_VALUE) {
    expectedValue = field.value;
  }

  if (isLineItemLikeMatch(rawRule) && validationResult.resultMetadata) {
    const ruleMetadata = JSON.parse(validationResult.resultMetadata);
    if (ruleMetadata.hasOwnProperty(CONSUMED_QUANTITY)) {
      expectedValue = formatExpectedValueField(ruleMetadata.consumedQuantity);
    } else {
      expectedValue = JSON.parse(field.value).quantity.toString();
    }
  }

  if (!expectedValue && validationResult.ruleMetadata)
    expectedValue = extractExpectedValueFromRuleMetadata(
      rawRule,
      validationResult.ruleMetadata
    );

  return convertField(expectedValue);
};

const getValue = (validationResult: ValidationResult, field: Field) => {
  const rawRule = validationResult.rule;
  let value: string | undefined;

  if (field.purpose === AuthoritativeDataPurpose.VALIDATION_SUBJECT) {
    if (isLineItemLikeMatch(rawRule) && validationResult.resultMetadata) {
      value = extractValueForLineItemMatch(
        field.value,
        validationResult.resultMetadata
      );
    } else {
      value = field.value;
    }
  }

  if (rawRule === MatchingRule.CASE_PACK_MATCH) {
    const { casePackAttributes } = JSON.parse(field.value).metadata;
    if (casePackAttributes !== undefined) {
      value = casePackAttributes?.originalQuantity;
    }
  }
  return convertField(value);
};

const getItemValue = (
  validationResult: ValidationResult,
  field: Field,
  t: any
) => {
  if (
    field.purpose !== AuthoritativeDataPurpose.REFERENCE_VALUE ||
    field.itemPrefix
  ) {
    return convertField(t(field.itemValue));
  }
  return null;
};

const getItem = (validationResult: ValidationResult, field: Field, t: any) => {
  if (field.itemPrefix) {
    return convertField(`${t(field.itemPrefix)}: ${t(field.itemValue)}`);
  }

  if (field.purpose !== AuthoritativeDataPurpose.REFERENCE_VALUE) {
    return convertField(t(field.itemValue));
  }
  return null;
};

const getDetails = (
  validationResult: ValidationResult,
  field: Field,
  t: any
) => {
  const rawRule = validationResult.rule;
  const defaultRuleDetailsApplier = getRuleDetailsApplier(rawRule!);

  const details = defaultRuleDetailsApplier(
    t(validationResult.comment),
    field.value,
    t
  );

  return convertField(details) || EMPTY_FIELD;
};

const getSopInstructions = (stringId: string, t: any) => {
  let content;
  const tConfig = { defaultValue: "" };

  if (isVendorCentral()) {
    content = t(`${stringId}_external_sop`, tConfig);
  } else {
    content = t(`${stringId}_internal_sop`, tConfig);
  }

  if (!content) {
    return t(`${stringId}_sop`, tConfig);
  }
  return content;
};

const getRuleDescription = (validationResult: ValidationResult): string => {
  if (validationResult.ruleMetadata) {
    const ruleMetadata = JSON.parse(validationResult.ruleMetadata);
    if (ruleMetadata.customRuleName) {
      return ruleMetadata.customRuleName;
    }
  }
  return validationResult.ruleDescription;
};

export default function convertToMismatchTableFields(
  validationResult: ValidationResult,
  index: number,
  t?: any
): MismatchTableFields {
  let expectedValue: string | undefined;
  let details: string | undefined;
  let value: string | undefined;
  let sopDetails: string | undefined;
  let itemValue = EMPTY_FIELD;
  let item = EMPTY_FIELD;

  const { severity } = validationResult;

  const ruleDescription = getRuleDescription(validationResult);
  const rule = convertField(t(ruleDescription)) || EMPTY_FIELD;

  validationResult.fields.forEach((field) => {
    itemValue = getItemValue(validationResult, field, t) || itemValue;
    expectedValue = expectedValue || getExpectedValue(validationResult, field);
    item = getItem(validationResult, field, t) || item;
    value = value || getValue(validationResult, field);
    details = getDetails(validationResult, field, t);
    sopDetails = getSopInstructions(validationResult.comment, t);
  });

  return <MismatchTableFields>{
    index,
    item,
    rule,
    value: value || EMPTY_FIELD,
    expectedValue: expectedValue || EMPTY_FIELD,
    details,
    ruleSeverity: severity || EMPTY_FIELD,
    metadata: { itemValue },
    sopDetails,
  };
}

export const orderMismatchTableByAscendingItems: MismatchTableRowSorter<
  MismatchTableFields
> = ({ item: x }, { item: y }) => {
  return x > y ? SHOULD_SWAP : SHOULD_NOT_SWAP;
};
